/* Russian (UTF-8) initialisation for the jQuery UI date picker plugin. */
/* Written by Andrew Stromnov (stromnov@gmail.com). */
jQuery(($) => {
    if ($.datepicker) {
        $.datepicker.regional.ru = {
            closeText: 'Закрыть',
            prevText: '&#x3c;Пред',
            nextText: 'След&#x3e;',
            currentText: 'Сегодня',
            monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн',
                'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            dayNames: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            dayNamesShort: ['вск', 'пнд', 'втр', 'срд', 'чтв', 'птн', 'сбт'],
            dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekHeader: 'Нед',
            dateFormat: 'dd.mm.yy',
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: '' };
        $.datepicker.setDefaults($.datepicker.regional.ru);
    }
});

/* вписывает в url якоря вкладок ui табов */
function setAnchors(anchor) {
    let url = window.location.href.split('#')[0];
    window.location.href = `${url}#${anchor || $('#tabs').children('div:first').attr('id')}`;
    $('.paginator a').each(function () {
        url = $(this).attr('href').split('#')[0];
        $(this).attr('href', `${url}#${anchor || 'floor1'}`);
    });
}
window.setAnchors = setAnchors;

function delete_request(url, success) {
    $.ajax({
        url,
        type: 'DELETE',
        success,
    });
}

window.delete_request = delete_request;

function popup_modal(options) {
    function show_popup() {
        container.dialog({
            width,
            height,
            modal: true,
            resizable: false,
            open(event, ui) {
                $(this).css({ 'max-height': height, 'overflow-y': 'auto' });
            },
            close(ev, ui) {
                $(this).dialog('destroy');
                if (close_callback) {
                    close_callback();
                }
            },
            title: caption,
        });
    }
    var caption = options.caption || '';
    var width = options.width || 'auto';
    var height = options.height || 'auto';
    const url = options.url;
    const html = options.html;
    var close_callback = options.close_callback || '';
    var container = $('#dialog_container');
    if (url) {
        container.load(url, () => {
            show_popup();
        });
    } else if (html) {
        container.html(html);
        show_popup();
    }
    return false;
}

window.popup_modal = popup_modal;

function error_popup() {
    let msg = '<span style="display:block; font-weight:bold; margin-bottom:20px; text-align:center;">';
    msg += 'Что-то пошло не так. Пожалуйста, попробуйте снова.</span>';
    msg += '<p style="text-align:center">Скорее всего мы уже знаем об этом, ';
    msg += 'но если ошибка продолжает возникать, вы можете отправить письмо ';
    msg += 'с описанием проблемы на <a href="mailto:sysfeedback@ml.corp.mail.ru">sysfeedback@ml.corp.mail.ru</a></p>';
    popup_modal({
        html: msg,
        caption: 'Произошла ошибка',
        width: 500,
    });
}

window.error_popup = error_popup;

function forbidden_popup() {
    let msg = '<span style="display:block; font-weight:bold; margin-bottom:20px; text-align:center;">';
    msg += 'Кажется, у вас нет права на это действие.</span>';
    // Эта функция используется только в разделе невыходов/опозданий.
    // Если нужно использовать в другом месте, стоит переписать текст более generic.
    msg += '<p style="text-align:center">Возможно, этот случай уже закрыт HR\'ом</p>';
    popup_modal({
        html: msg,
        caption: 'Произошла ошибка',
        width: 500,
    });
}

window.forbidden_popup = forbidden_popup;

function create_ok_dialog(options) {
    function initialize_dialog() {
        container.dialog({
            width,
            height,
            modal: true,
            position: ['center'],
            resizable: false,
            autoOpen: false,
            open(event, ui) {
                $(this).css({ 'max-height': height, 'overflow-y': 'auto' });
            },
            close(ev, ui) {
                if (close_callback) {
                    close_callback();
                }
                $(this).dialog('destroy');
            },
            title: caption,
            buttons: buttons_hash,
        });
        container.parent().css('position', 'fixed').css(css_options).end()
            .dialog('open');
    }
    var caption = options.title || '';
    var width = options.width || 'auto';
    var height = options.height || 'auto';
    var close_callback = options.close_callback || function () {};
    var buttons_hash = {};
    var css_options = options.css || {};
    var container = $('#dialog_container');
    container.html(options.text);
    initialize_dialog();
    return false;
}

window.create_ok_dialog = create_ok_dialog;

function create_info_dialog(options) {
    function initialize_dialog() {
        container.dialog({
            width,
            height,
            modal: true,
            position: ['center'],
            resizable: false,
            autoOpen: false,
            open(event, ui) {
                $(this).css({ 'max-height': height, 'overflow-y': 'auto' });
            },
            close(ev, ui) {
                if (close_callback) {
                    close_callback();
                }
                $(this).dialog('destroy');
            },
            title: caption,
            buttons: buttons_hash,
        });
        container.parent().css('position', 'fixed').css(css_options).end()
            .dialog('open');
    }
    var caption = options.caption || '';
    var width = options.width || 'auto';
    var height = options.height || 'auto';
    var close_callback = options.close_callback;
    var buttons_hash = options.buttons || {};
    const url = options.url;
    var css_options = options.css || {};
    var container = $('#dialog_container');
    const callback = options.callback;
    if (url) {
        $.ajax({
            url,
            type: 'GET',
            dataType: 'html',
            statusCode: {
                500() {
                    error_popup();
                    close_callback();
                },
            },
            success(data) {
                container.html(data);
                initialize_dialog();
            },
        });
    }
    return false;
}

window.create_info_dialog = create_info_dialog;

/* создает ui-диалог в который помещает данные полученные по ссылке из href-атрибута элемента по которому был произведен клик */
function create_edit_dialog(options) {
    function initialize_dialog() {
        container.dialog({
            width,
            height,
            modal: true,
            position: ['center'],
            resizable: false,
            autoOpen: false,
            open(event, ui) {
                $(this).css({ 'max-height': $(window).height() - 130, 'overflow-y': 'auto' });
            },
            close(ev, ui) {
                if (close_callback) {
                    close_callback();
                }
                $(this).dialog('destroy');
            },
            title: caption,
            buttons: buttons_hash,
        });
        container.parent().css('position', 'fixed').css(css_options).end()
            .dialog('open');
    }
    var caption = options.caption || '';
    var width = options.width || 'auto';
    var height = options.height || 'auto';
    var close_callback = options.close_callback;
    var buttons_hash = options.buttons || {};
    const url = options.url;
    var css_options = options.css || {};
    var container = $('#dialog_container');
    const callback = options.callback;
    const initFunction = options.initFunction;

    const ok_caption = options.ok_caption || 'Сохранить';
    const draft_caption = options.draft_caption;
    const ok_without_email_caption = options.ok_without_email_caption;

    function create_callback(form, notify) {
        submit_edit_form(form, url, (data) => {
            if (typeof (callback) !== 'undefined') {
                callback(data);
            }

            if (data.msg === 'reload') {
                window.location.reload();
            } else if (data.msg) {
                window.location = data.msg;
            }
        });
    }

    if (ok_without_email_caption) {
        buttons_hash[ok_without_email_caption] = function () {
            create_callback($(this).find('form'), 0);
        };
    }

    if (draft_caption) {
        buttons_hash[draft_caption] = function () {
            submit_edit_form($(this).find('form'), url, callback);
        };
    }

    buttons_hash[ok_caption] = function () {
        create_callback($(this).find('form'), 1);
    };

    const buttons = [];
    Object.keys(buttons_hash).forEach((key) => {
        const klass = (key === ok_caption) ? 'p-color-blue' : 'p-color-gray';
        buttons.push({
            text: key,
            class: klass,
            click: buttons_hash[key],
        });
    });

    buttons_hash = buttons;

    if (url) {
        $.ajax({
            url,
            type: 'GET',
            dataType: 'html',
            statusCode: {
                500: () => {
                    error_popup();
                    close_callback();
                },
                403: () => {
                    forbidden_popup();
                    close_callback();
                },
            },
            success(data) {
                container.html(data);
                if (initFunction) {
                    initFunction();
                }
                initialize_dialog();
            },
        });
    }
    return false;
}

window.create_edit_dialog = create_edit_dialog;

function create_confirm_dialog(options) {
    const ok_callback = options.ok_callback || function () {};
    const cancel_callback = options.cancel_callback || function () {};
    options.buttons = options.buttons || {};
    options.buttons[options.ok_caption || 'OK'] = function () {
        ok_callback();
        $(this).dialog('destroy');
    };
    options.buttons[options.cancel_caption || 'Отмена'] = function () {
        cancel_callback();
        $(this).dialog('destroy');
    };
    return create_confirm_dialog_ex(options);
}

window.create_confirm_dialog = create_confirm_dialog;

function create_confirm_dialog_ex(options) {
    const close_callback = options.close_callback || function () {};
    $('#dialog_container').html(options.text || '').dialog({
        resizable: false,
        modal: true,
        width: options.width || 500,
        close(ev, ui) {
            close_callback();
            $(this).dialog('destroy');
        },
        title: options.caption || 'Подтверждение',
        buttons: options.buttons || [],
    });
    return false;
}

window.create_confirm_dialog_ex = create_confirm_dialog_ex;

function enable_buttons(form) {
    const buttons = form.parents('.ui-dialog').find('.ui-button');
    buttons.attr('disabled', false).removeClass('ui-state-disabled');
}

window.enable_buttons = enable_buttons;

function form_display_message(form, msg) {
    enable_buttons(form);
    const buttons = form.parents('.ui-dialog').find('.ui-dialog-buttonset');
    form.parents('.ui-dialog').find('.error').remove();
    buttons.prepend(`<span class="error">${msg}</span>`);
}

window.form_display_message = form_display_message;

function form_display_error_html(form, html) {
    enable_buttons(form);
    const buttons = form.parents('.ui-dialog').find('.ui-dialog-buttonset');
    buttons.parent().find('.error_html').remove();
    buttons.before(`<div class="error_html">${html}</div>`);
}

window.form_display_error_html = form_display_error_html;

function process_form(form, data, callback) {
    const container = form.parents().find('#dialog_container');
    $('.error_container').hide();
    form.parents('.ui-dialog').find('.error_html').remove();
    form.find('.error').hide();
    if (data.result === 'OK') {
        if (typeof (callback) !== 'undefined') {
            try {
                container.dialog('destroy');
            } catch (e) {}
            callback(data);
        } else if (data.noty !== undefined) {
            container.dialog('destroy');
            notify_success(data.noty, true);
        } else if (data.msg === 'reload' || !data.msg) {
            window.location.reload();
        } else {
            container.dialog('destroy');
            window.location = data.msg;
        }
    } else if (data.result === 'redraw') {
        container.html(data.msg);
    } else if (data.result === 'redirect') {
        container.dialog('destroy');
        window.location = data.msg;
    } else if (data.result === 'error') {
        if (data.error_html !== undefined) {
            form_display_error_html(form, data.error_html);
        } else {
            form_display_message(form, data.msg);
        }
    } else {
        enable_buttons(form);
        form.find('input').css('background-color', '');
        for (const index in data.msg) {
            const field_name = index;
            const field_error = data.msg[index];
            form.find(`#${index}`).html(field_error.toString());
            form.find(`#${index}`).fadeIn();
            $(`#error_container_${index}`).fadeIn();
            const field_selector = form.find(`#id_${index},.field_${index}`);
            field_selector.click(function () {
                $(this).css('background-color', 'white');
            });
            field_selector.css('background-color', '#FFB3B3');
        }
    }
}

window.process_form = process_form;

function disable_buttons(form) {
    const buttons = form.parents('.ui-dialog').find('.ui-button');
    buttons.attr('disabled', 'disabled').addClass('ui-state-disabled');
}

window.disable_buttons = disable_buttons;

function form_hide_errors(form) {
    form.parents('.ui-dialog').find('.ui-dialog-buttonpane .error').remove();
}

window.form_hide_errors = form_hide_errors;


/* общий обработчик ajax-форм в проекте. Ссылка берется из action атрибута формы,
    ответ - json, в итоге если все ОК, запускается callback функция(если она указана) или следуем по полученной ссылке или обновляем страницу,
	либо в случае неудачи отображаем ошибки формы рядом с полями */
function submit_edit_form(form, url, callback, formDataCallback) {
    if (typeof tinyMCE !== 'undefined') {
        for (const index in tinyMCE.editors) {
            const editor = tinyMCE.editors[index];
            $(`#${editor.id}`).val(editor.getContent());
        }
    }
    const params = {
        url,
        type: 'POST',
        dataType: 'json',
        statusCode: {
            500(data) {
                form_display_message(form, 'Произошла ошибка, пожалуйста, попробуйте снова');
            },
            403(data) {
                if (data.responseText) {
                    form_display_message(form, data.responseText);
                } else {
                    form_display_message(form, 'Недостаточно прав для совершения операции');
                }
            },
            400(data) {
                if (data.responseJSON && data.responseJSON.error) {
                    form_display_message(form, data.responseJSON.error);
                }
            },
        },
        xhr() {
            const xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener('progress', (evt) => {
                if (evt.lengthComputable) {
                    const percentComplete = Math.round((evt.loaded / evt.total * 100 - 1));
                    if (evt.total > 50000) {
                        form_display_message(form,
                            `${'<div class="ui-dialog-loader-box">' +
                            '<div class="ui-dialog-loader-cursor" style="width:'}${percentComplete}%;"></div>` +
                            '</div>',
                        );
                    }
                }
            }, false);
            return xhr;
        },
    };
    let data;
    if (!window.FormData) {
        // for old browsers - do not support forms with file input fields.
        data = form.serialize();
    } else {
        data = new FormData(form[0]);
        if (formDataCallback) {
            formDataCallback(data);
        }
        params.processData = false;
        params.contentType = false;
    }
    params.data = data;
    disable_buttons(form);
    form_hide_errors(form);
    $.ajax(params).done((data) => {
        process_form(form, data, callback);
    });
    return false;
}

window.submit_edit_form = submit_edit_form;

function get_async_json(url, method, callback, json_data) {
    $.ajax({
        url,
        type: method,
        data: typeof json_data !== 'undefined' ? json_data : null,
        dataType: 'json',
        statusCode: {
            500() {
                popup_modal({ html: '<p>Произошла ошибка, пожалуйста, попробуйте снова</p>' });
            },
            403() {
                popup_modal({ html: '<p>Недостаточно прав для совершения операции</p>' });
            },
        },
        success(data) {
            callback(data);
        },
    });
    return null;
}

window.get_async_json = get_async_json;

function init_counters() {
    $.each($('.sys-counter'), function () {
        const counter = $(this);
        const prefix = counter.data('prefix') || '';
        const url = counter.data('counter-url');
        $.get(url, {}, (data) => {
            const counter_html = prefix + data;
            if (parseInt(data, 10) !== 0) {
                counter.html(counter_html);
                counter.removeClass('sys-counter');
            }
        }, 'html');
    });
    return false;
}

window.init_counters = init_counters;

function initialize_input() {
    /* jquery autocomplete select */
    $('input[data-ac-source]').each(function () {
        const params = {
            help: $(this).data('ac-help'),
            prefix: $(this).data('ac-prefix'),
            source: $(this).data('ac-source'),
        };
        if ($(this).data('ac-initial-id') && $(this).data('ac-initial-value')) {
            params.initial = {
                id: $(this).data('ac-initial-id'),
                value: $(this).data('ac-initial-value'),
            };
        }
        if ($(this).data('ac-user')) {
            params.user = $(this).data('ac-user');
        }
        $(this).autoselect(params);
    });
}

window.initialize_input = initialize_input;

function publish(selector, url, nonotify) {
    $.post(url, { notify: nonotify ? 0 : 1 }, (data) => {
        if (data.result === 'OK') {
            $(selector).removeClass('rounded-corners not_pub');
            $(`${selector} .ajax_publish_button`).hide();
            $(`${selector} #not_published_span`).remove();
        }
    }, 'json');
}

window.publish = publish;

function set_active(selector, url) {
    $.post(url, (data) => {
        if (data.result === 'OK') {
            // $(selector +' .ajax_set_active_button').hide();
            $(`${selector} .not_act`).hide();
            if (data.msg === 'reload' || !data.msg) {
                window.location.reload();
            }
        }
    }, 'json');
}

window.set_active = set_active;

function notify_users(url) {
    $.post(url, (data) => {
        if (noty) {
            noty({
                text: data.msg,
                layout: 'bottomRight',
                type: data.result === 'OK' ? 'success' : 'error',
                timeout: 25000,
            });
        }
    }, 'json');
}

window.notify_users = notify_users;

function delete_object_by_url(url, elem_selector, redirect_url) {
    delete_request(url, (data) => {
        if (data.redirect) {
            redirect_url = data.redirect;
        }
        if (redirect_url) {
            redirect(redirect_url);
            return;
        }
        if (elem_selector) {
            $(elem_selector).fadeOut().remove();
        } else {
            location.reload();
        }
    });
}

window.delete_object_by_url = delete_object_by_url;

function initialize_ajax_buttons(element) {
    // Кнопки удаления объектов
    if (!element) {
        element = $(document);
    }

    element.on('click', '.checkbox_toggler', function () {
        const selector = $(this).data('toggle-selector');
        $(selector).parents('.field-container').toggle();
    });

    element.on('click', '.ajax_delete_button', function () {
        const url = $(this).attr('href');
        const prompt_text = $(this).data('prompt-text');
        const elem_selector = $(this).data('elem-selector');
        const redirect_url = $(this).data('redirect-url');
        if (prompt_text) {
            create_confirm_dialog({
                text: prompt_text,
                ok_callback() {
                    delete_object_by_url(url, elem_selector, redirect_url);
                },
            });
        } else {
            delete_object_by_url(url, elem_selector, redirect_url);
        }
        return false;
    });

    // Нужно только для постов в блоге. Кнопка для рассылки повторных уведомлений о посте в блоге
    element.on('click', '.ajax_notify_button', function () {
        const url = $(this).attr('href');
        const prompt_text = 'Сделать рассылку по пользователям, которые подписались на блог';
        create_confirm_dialog({
            text: prompt_text,
            ok_callback() {
                notify_users(url);
            },
        });
        return false;
    });

    element.on('click', '.ajax_edit_button', function () {
        const url = $(this).attr('href');
        const caption_text = $(this).data('form-caption');
        const width = $(this).data('form-width');
        const height = $(this).data('form-height');
        const replace_selector = $(this).data('replace-selector');
        const callback_msg = $(this).attr('callback_msg');
        const data_change_selector = $(this).data('change-selector');
        const data_response_key = $(this).data('response-key');
        const ok_caption = $(this).data('ok-caption');
        const draft_caption = $(this).data('draft-caption');
        const content_type = $(this).data('content_type');
        const params = {
            caption: caption_text,
            url,
            width,
            height,
            ok_caption,
            draft_caption,
            content_type,
            edit: true,
        };
        if (callback_msg !== undefined) {
            params.callback = function (data) {
                notify_success(callback_msg);
            };
        }
        if (replace_selector) {
            params.callback = function (data) {
                const elem = $(replace_selector).parent();
                elem_replace(data, replace_selector, curry(initialize_ajax_buttons, elem));
            };
        }
        if (data_change_selector && data_response_key) {
            params.callback = function (data) {
                const elem = $(data_change_selector);
                elem.text(data[data_response_key]);
            };
        }
        create_edit_dialog(params);
        return false;
    });


    element.on('click', '.ajax_publish_button', function () {
        const url = $(this).attr('href');
        const prompt_text = $(this).data('prompt-text');
        const elem_selector = $(this).data('elem-selector');
        if (prompt_text) {
            const buttons = {};
            if ($(this).data('ask-notify')) {
                buttons['Без письма'] = function () {
                    publish(elem_selector, url, true);
                    $(this).dialog('destroy');
                };
            }
            create_confirm_dialog({
                text: prompt_text,
                ok_callback() {
                    publish(elem_selector, url);
                },
                buttons,
            });
        } else {
            publish(elem_selector, url);
        }
        return false;
    });

    element.on('click', '.ajax_set_active_button', function () {
        const url = $(this).attr('href');
        const prompt_text = $(this).data('prompt-text');
        const elem_selector = $(this).data('elem-selector');
        if (prompt_text) {
            create_confirm_dialog({
                text: prompt_text,
                ok_callback() {
                    set_active(elem_selector, url);
                },
            });
        } else {
            set_active(elem_selector, url);
        }
        return false;
    });

    element.on('click', '.ajax_archive_button', function () {
        const url = $(this).attr('href');
        const prompt_text = $(this).data('prompt-text');
        const elem_selector = $(this).data('elem-selector');
        const redirect_url = $(this).data('url');
        const data = {
            url,
            type: 'POST',
            ok_callback() {},
        };
        if (prompt_text) {
            create_confirm_dialog({
                text: prompt_text,
                ok_callback() {
                    if (redirect_url && redirect_url != $(location).attr('pathname')) {
                        delete_ajax_callback(data);
                        $(location).attr('href', redirect_url);
                    } else {
                        delete_element_post(url, elem_selector);
                    }
                },
            });
        } else if (redirect_url && redirect_url != $(location).attr('pathname')) {
            delete_ajax_callback(data);
            $(location).attr('href', redirect_url);
        } else {
            delete_element_post(url, elem_selector);
        }

        return false;
    });

    element.find('.ajax_comeback_button').click(function () {
        const url = $(this).attr('href');
        const prompt_text = $(this).data('prompt-text');
        const elem_selector = $(this).data('elem-selector');
        const redirect_url = $(this).data('url');
        if (prompt_text) {
            create_confirm_dialog({
                text: prompt_text,
                ok_callback() {
                    comeback_element(url, elem_selector);
                },
            });
            if (redirect_url) {
                $(location).attr('href', redirect_url);
            }
        } else {
            comeback_element(url, elem_selector);
        }
        return false;
    });

    element.on('click', '.ajax_add_button', function () {
        // changing this part somehow breaks ads-related code, please proceed with care
        let url = $(this).data('default-href');
        if (url == undefined) {
            url = $(this).attr('href');
        }

        if ($(this).data('habr') === '1') {
            url = $(this).attr('href');
        }

        const caption_text = $(this).data('form-caption');
        const width = $(this).data('form-width');
        const height = $(this).data('form-height');
        const ok_caption = $(this).data('ok-caption');
        const draft_caption = $(this).data('draft-caption');
        const ok_without_email_caption = $(this).data('ok_without_email-caption');
        const appendto_selector = $(this).data('appendto-selector');
        const prependto_selector = $(this).data('prependto-selector');
        const content_type = $(this).data('content_type');
        const params = {
            caption: caption_text,
            url,
            width,
            height,
            ok_caption,
            draft_caption,
            ok_without_email_caption,
            content_type,
        };
        if (appendto_selector) {
            params.callback = function (data) {
                const elem = $(appendto_selector);
                elem_append(data, appendto_selector, curry(initialize_ajax_buttons, elem));
            };
        } else if (prependto_selector) {
            params.callback = function (data) {
                const elem = $(prependto_selector);
                elem_prepend(data, prependto_selector, curry(initialize_ajax_buttons, elem));
            };
        }
        create_edit_dialog(params);
        if ($('.question_container_builder').length > 1) {
            $('.shadow_button').show();
        }
        return false;
    });

    // переключение связей юзера и объекта
    element.on('click', '.toggle-relation', function () {
        const self = $(this); // checkbox
        const relation_on_url = self.data('relation_on_url');
        const relation_off_url = self.data('relation_off_url');
        const content_type_id = self.data('content-type-id');
        const object_id = self.data('object-id');
        const relation_type = self.data('relation-type');
        const data = {
            content_type_id,
            object_id,
            relation_type,
        };

        let link = relation_off_url;
        if (self[0].checked) {
            link = relation_on_url;
        }
        $.post(link, data, (data) => {
            const status = data.status;
            if (status) {
                if (status === 'off') {
                    self.prop('checked', false);
                } else if (status === 'on') {
                    self.prop('checked', true);
                }
            }
        }, 'json');
        return false;
    });
}

window.initialize_ajax_buttons = initialize_ajax_buttons;

$(document).ready(() => {
    // Show go top control
    if ($.GoTop) {
        $.GoTop({});
        $(window).scroll();
    }

    initialize_input();
    initialize_ajax_buttons();
    init_wallpaper();

    // ui табы
    $('#tabs').tabs({
        create() {
            setAnchors(window.location.href.split('#')[1]);
        },
        select(event, ui) {
            setAnchors(ui.tab.href.split('#')[1]);
        },
    });

    /* подключает UI календарик с выбором времени */
    $('.datetimepicker').each(function () {
        const now = new Date();
        const h = now.getHours();
        const m = now.getMinutes();
        $(this).datetimepicker({
            changeMonth: true,
            changeYear: true,
            yearRange: '1900:2100',
            dateFormat: 'dd.mm.yy',
            hour: h,
            minute: m,
        });
    });

    /* По клику открываем окно диалога в веб-агенте */
    $('body').on('click', '.m-agent-link', function () {
        const self = $(this);
        if (!window.WebAgent) {
            const html = self.parent().find('.m-agent-help').html();
            popup_modal({ html });
            return false;
        }
        try {
            window.WebAgent.API.openDialog('key - not used', self.data('agent'));
        } catch (e) {
            console.log(e);
        }
        return false;
    });

    /* По клику публикует скрытую запись о сотруднике */
    $('.transition_content').on('click', '.transition-publish', function () {
        const self = $(this);
        const url = self.data('url');
        $.post(url, {}).success(() => {
            notify_success('Запись опубликована', true);
            self.parentsUntil('.transition_content').hide();
        }).error(() => {
            $.noop();
        });
    });

    /* подключает UI календарик  */
    $('.datepicker').each(function () {
        $(this).datepicker({ changeMonth: true, changeYear: true, yearRange: '1900:2100', dateFormat: 'dd.mm.yy' });
    });

    $(() => {
        $('.sys_toggle_link').click(function () {
            $(this).parent().find('.sys_toggle_link_container').slideToggle(100);
        });
    });

    SysComments.init();

    $('a.ui-button,button.ui-button,li.ui-state-default').hover(function () {
        $(this).addClass('ui-state-hover');
    }, function () {
        $(this).removeClass('ui-state-hover');
    });

    init_counters();

    const limits = $('#login_data').attr('user-limit');
    if (limits && limits.length) {
        $('.antispy-limit').addClass('antispy');
        const limit_classes = limits.split('&');
        for (let i = 0; i < limit_classes.length; i++) {
            $(`.${limit_classes[i]}`).removeClass('antispy');
        }
        $('.antispy').attr('href', '#');
    }

    init_toggleitems();
    $('.dropdown-toggle').dropdown();
    init_item_control();
});


function init_item_control() {
    $('.item_control, .js-item-control').hover(
        function () {
            $(this).find('.delete_item a').removeClass('hidden').addClass('active');
        },
        function () {
            $(this).find('.delete_item a').removeClass('active').addClass('hidden');
        },
    );

    $('.delete_item a').click(function () {
        const that = $(this);
        create_confirm_dialog({
            text: that.data('confirm-text'),
            ok_callback() {
                delete_ajax_callback({
                    url: that.attr('href'),
                    elem: that.data('hide-element') ? $(that.data('hide-element')) : that.parent().parent(),
                    type: that.hasClass('cbv-type') ? 'DELETE' : 'POST',
                });
            },
        });
        return false;
    });
}
window.init_item_control = init_item_control;

function delete_item(elem, url, query_type) {
    return delete_ajax_callback({
        url,
        elem,
        type: query_type || 'POST',
    });
}

window.delete_item = delete_item;

function init_toggleitems() {
    $('.toggleitems .item').each(function () {
        const item = $(this);
        item.find('.header .pseudo-link').click(() => {
            item.toggleClass('active');
        });
    });
}

window.init_toggleitems = init_toggleitems;

function delete_element(url, selector) {
    // request given url (GET) and deletes element with given selector
    return delete_ajax_callback({
        url,
        elem: $(selector).hasClass('video-js') ? $(selector).parent() : $(selector),
    });
}

window.delete_element = delete_element;


function delete_element_post(url, selector) {
    // request given url by POST and deletes element with given selector
    return delete_ajax_callback({
        url,
        elem: $(selector).hasClass('video-js') ? $(selector).parent() : $(selector),
        type: 'POST',
    });
}


window.delete_element_post = delete_element_post;

function comeback_element(url, selector) {
    // sends POST request to url to comeback element
    $.post(url, {}).success(() => {
        window.location.reload();
        notify_success('Запись актуализирована', true);
    }).error(() => {
        $.noop();
    });
}


window.comeback_element = comeback_element;

function delete_elem_redirect(elem, selector, link) {
    return delete_ajax_callback({
        url: $(elem).attr('href'),
        ok_callback() { window.location.href = link; },
    });
}


window.delete_elem_redirect = delete_elem_redirect;

function set_archive_style(elem) {
    $(elem).addClass('archive-entry');
    $($(elem).find('.ajax_archive_button').hide());
}


window.set_archive_style = set_archive_style;

function delete_ajax_callback(params) {
    params = $.extend({
        url: '',
        type: 'GET',
        ok_callback(data, elem) {
            if (elem) {
                if (data.msg === 'archive') {
                    set_archive_style(elem);
                } else {
                    elem.fadeOut();
                }
            }
        },
        error_callback(data) {
            if (data.result === 'Fail' && data.msg && data.caption) {
                popup_modal({
                    html: data.msg,
                    caption: data.caption,
                    width: 600,
                    height: 120,
                });
            }
        },
    }, params);

    $.ajax({
        url: params.url,
        type: params.type,
        dataType: 'json',
        success(data) {
            if (data.result === 'OK') {
                if (params.ok_callback) {
                    params.ok_callback(data, params.elem);
                }
            } else if (params.error_callback) {
                params.error_callback(data, params.elem);
            }
        },
    });
    return false;
}


window.delete_ajax_callback = delete_ajax_callback;

function elem_prepend(data, prependTo_selector, callback) {
    $(data.msg).hide().prependTo(prependTo_selector).slideDown(300, () => {
        if (callback) {
            callback();
        }
    });
}

window.elem_prepend = elem_prepend;

function elem_replace(data, replace_selector, callback) {
    $(replace_selector).fadeOut(300, function () {
        $(this).replaceWith(data.msg);
        if (callback) {
            callback();
        }
    }).fadeIn();
}


window.elem_replace = elem_replace;

function curry() {
    const func = arguments[0];
    const args = [];
    for (let i = 1; i < arguments.length; i++) {
        args.push(arguments[i]);
    }
    return function () { func.apply(this, args); };
}


window.curry = curry;

function redirect(url) {
    if (url.indexOf('#') !== -1) {
        window.location.href = url;
        window.location.reload();
    } else {
        window.location = url;
    }
}


window.redirect = redirect;

function notify_success(message, autoclose) {
    noty({ text: message, layout: 'bottomRight', type: 'success' });
    if (autoclose !== undefined) {
        setTimeout($.noty.closeAll, 10000);
    }
}


window.notify_success = notify_success;

function init_badge_users_list() {
    let url = $('#datadiv').attr('users-url');
    if (!url) {
        const badge = $('#datadiv').attr('badge');
        url = `/badges/users/${badge}/`;
    }
    $.get(url, (data) => {
        if (data.result === 'OK') {
            $.map(data.users, (item) => {
                $('#badge_users').append(item);
            });
            init_toggleitems();
        }
    });
}


window.init_badge_users_list = init_badge_users_list;

function bind_restriction_events(unrestrict_callback, restrict_callback, selector) {
    if (selector === undefined) {
        selector = document;
    }
    const restrict_create_selector = '.restrict_create';
    const restrict_delete_selector = '.restrict_delete';
    const restrictions_selector = '.restrictions';
    $(selector).find(restrict_delete_selector).unbind('click').click(function () {
        const self = $(this);
        $.post(self.attr('href'), (data) => {
            if (data.result === 'OK') {
                self.hide();
                self.parent().parent().find(restrict_create_selector).show();
                self.parent().parent().find(restrictions_selector).hide();
                unrestrict_callback(data);
            }
        });
        return false;
    });
    $(selector).find(restrict_create_selector).unbind('click').click(function () {
        const self = $(this);
        $.post(self.attr('href'), (data) => {
            if (data.result === 'OK') {
                self.hide();
                self.parent().parent().find(restrict_delete_selector).show();
                self.parent().parent().find(restrictions_selector).show()
                    .trigger('click');
                restrict_callback(data);
            }
        });
        return false;
    });
}


window.bind_restriction_events = bind_restriction_events;

function init_wallpaper() {
    (function () {
        const background_image = $('#background-image');
        if (background_image.length == 1) {
            $(`#${background_image.attr('data-content_id')}`)
                .css('background', `url(${background_image.attr('data-image_url')}) repeat scroll 50% top`);
        }
    }());
}


window.init_wallpaper = init_wallpaper;

function hasClass(elem, className) {
    if (elem.className == className) { return true; }
    if (elem.className.indexOf(className) >= 0) {
        classnames = className.split(' ');
        for (i = 0; i < classnames.length; i++) {
            if (classnames[i] == className) { return true; }
        }
    }
    return false;
}


window.hasClass = hasClass;

// using jQuery
// TODO (ibragimov): get rid of it. There is a duplicate of this function in utils.
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie != '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (`${name}=`)) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


window.getCookie = getCookie;

function sameOrigin(url) {
    // test that a given url is a same-origin URL
    // url could be relative or scheme relative or absolute
    const host = document.location.host; // host + port
    const protocol = document.location.protocol;
    const sr_origin = `//${host}`;
    const origin = protocol + sr_origin;
    // Allow absolute or scheme relative URLs to same origin
    return (url == origin || url.slice(0, origin.length + 1) == `${origin}/`) ||
        (url == sr_origin || url.slice(0, sr_origin.length + 1) == `${sr_origin}/`) ||
        // or any other URL that isn't scheme relative or absolute i.e relative.
        !(/^(\/\/|http:|https:).*/.test(url));
}


window.sameOrigin = sameOrigin;

$.ajaxPrefilter((options, originalOptions, jqXHR) => {
    const request_method = options.type.toLowerCase();
    if ((request_method === 'post' || request_method === 'delete') && sameOrigin(options.url)) {
        jqXHR.setRequestHeader('X-CSRFToken', getCookie('csrftoken'));
    }
});

$(() => {
    $('a.ui-button,button.ui-button,li.ui-state-default').hover(function () {
        $(this).addClass('ui-state-hover');
    }, function () {
        $(this).removeClass('ui-state-hover');
    });

    $(document.body).append('<div id="tooltip"></div>');
    if (!window.console) {
        $(document.body).append('<div id="debug"></div>');
    }
});
